import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import FacebookLink from '../components/links/FacebookLink';
import YoutubeLink from '../components/links/YoutubeLink';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';

import config from '../../config';

const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                keywords
                author
            }
        }
    }
`;

const IndexPage = () => (
    <StaticQuery
        query={query}
        render={data => {
            const {
                title,
                description,
                author,
                keywords,
            } = data.site.siteMetadata;
            return (
                <Layout
                    title={title}
                    description={description}
                    author={author}
                    keywords={keywords}
                >
                    <section id="banner">
                        <div className="inner">
                            <h2>{config.heading}</h2>
                            <p>{config.subHeading}</p>
                        </div>
                        <Scroll type="id" element="one">
                            <a href="#one" className="more">
                                En savoir plus
                            </a>
                        </Scroll>
                    </section>

                    <section id="one" className="wrapper style1 special">
                        <div className="inner">
                            <h2>Salut ! Moi c&apos;est Bruno.</h2>
                            <p>
                                Je suis passionné de Rock, et plus
                                particulièrement de Rock Français depuis plus de
                                30 ans. En voiture, à la maison ou dans des
                                salles de concert, j&apos;écoute tous les jours
                                des dizaines de titres.
                            </p>
                            <p>
                                La musique se doit d&apos;être partagée.
                                C&apos;est pourquoi, au fil de mes envies et de
                                mes découvertes, je partage avec vous le
                                meilleur de mes écoutes.
                            </p>
                            <p>
                                Cela se passe sur{' '}
                                <a href={config.facebookLink}>
                                    ma page Facebook
                                </a>{' '}
                                et{' '}
                                <a href={config.youtubeLink}>
                                    ma chaîne YouTube
                                </a>
                            </p>
                        </div>
                    </section>

                    <section id="two" className="wrapper alt style2">
                        <section className="spotlight">
                            <div className="image">
                                <img src={pic1} alt="" />
                            </div>
                            <div className="content">
                                <h2>Des grands classiques...</h2>
                                <p>
                                    Téléphone, Noir Désir, Indochine... que du
                                    bon, à écouter et à réécouter.
                                </p>
                            </div>
                        </section>
                        <section className="spotlight">
                            <div className="image">
                                <img src={pic2} alt="" />
                            </div>
                            <div className="content">
                                <h2>...aux dernières sorties</h2>
                                <p>
                                    Saez, Shaka Ponk, Skip The Use... la scène
                                    française bouge, et nous fait vibrer au son
                                    des grattes électriques.
                                </p>
                            </div>
                        </section>
                    </section>

                    <section id="cta" className="wrapper style4">
                        <div className="inner">
                            <header>
                                <h2>Tout se passe sur mes réseaux sociaux</h2>
                            </header>
                            <ul className="actions stacked">
                                <li>
                                    <FacebookLink
                                        className="fit"
                                        href={config.facebookLink}
                                        text="Facebook"
                                        title="Ma page Facebook"
                                    />
                                </li>
                                <li>
                                    <YoutubeLink
                                        className="fit"
                                        href={config.youtubeLink}
                                        text="YouTube"
                                        title="Ma chaîne YouTube"
                                    />
                                </li>
                            </ul>
                        </div>
                    </section>
                </Layout>
            );
        }}
    />
);

export default IndexPage;
