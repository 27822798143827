import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const styles = {
    facebook: {
        backgroundColor: '#ff0000',
        boxShadow: 'none',
    },
};

const YoutubeLink = ({ className, href, title, text }) => (
    <a
        className={classnames('button', className)}
        style={styles.facebook}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        title={title}
    >
        <i className="icon brands fa-youtube" />
        &nbsp;
        {text}
    </a>
);

YoutubeLink.propTypes = {
    className: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default YoutubeLink;
